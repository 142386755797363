import React from 'react';
import logo from './assets/logo.png';
import telegram from './assets/x-white-vh.webp';
import twitter from './assets/telegram-white-vh.webp';
import insta from './assets/insta-white-vh.webp';
import teaser from './assets/teaser.jpg';
import rta from './assets/rta.webp';
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <a
                    href="https://voyeurhouse.com/"
                >
                    <img src={logo} className="nav-logo" alt="logo" />
                </a>
                <p><a className="link_nostyling"
                      href="https://voyeurhouse.com/media">New videos</a> from
                    Voyeur-house - The biggest gallery of real life hidden cams
                </p>
                <a
                    href="https://voyeurhouse.com/live"
                >
                    <img src={teaser} className="App-logo" alt="teaser" />
                </a>
                <a
                    className="App-link"
                    href="https://voyeurhouse.com/media"
                >
                    Watch all cams
                </a>
            </header>
            <footer className="footer">
                <div className="container">
                    <div className="text-center">
                        <div className="row justify-content-center text-center">
                            <div
                                className="col-lg-2 col-md-2 col-sm-12 footer-section">
                            <span
                                className="section-title">Watch more cams</span>
                                <div className="row mb-0 mt-0"><a
                                    className="mr-0 ml-0" rel="nofollow"
                                    title="Back to all "
                                    href="/">Back to all </a><a rel="nofollow"
                                                                title="RealLifeCams"

                                                                href="/">RealLifeCams</a>
                                </div>
                                <div className="row mb-0 mt-0"><a
                                    className="mr-0 ml-0" rel="nofollow"
                                    title="Most active "
                                    href="/live">Most active </a><a
                                    rel="nofollow"
                                    title="RealLifeCam"
                                    href="/live">RealLifeCam</a>
                                </div>
                                <div className="row mb-0 mt-0"><a
                                    className="mr-0 ml-0" rel="nofollow"
                                    title="Last 24h of "
                                    href="/replay">Last 24h of </a><a
                                    rel="nofollow"
                                    title="RealLifeCams"
                                    href="/replay">RealLifeCams</a>
                                </div>
                                <div className="row mb-0 mt-0"><a
                                    className="mr-1 ml-0" rel="nofollow"
                                    title="Watch "
                                    href="/archive">Watch </a><a
                                    className="mr-0 ml-0" rel="nofollow"
                                    title="RealLifeCam"
                                    href="/archive">RealLifeCam</a><a
                                    rel="nofollow"
                                    title=" Archive"
                                    href="/archive"> Archive</a>
                                </div>
                                <div className="row mb-0 mt-0"><a
                                    className="mr-0 ml-0" rel="nofollow"
                                    title="Watch more "
                                    href="/live">Watch more </a><a
                                    rel="nofollow"
                                    title="live voyeur cams"
                                    href="/live">live
                                    voyeur cams</a></div>
                                <div className="row mb-0 mt-0"><a
                                    className="mr-0 ml-0" rel="nofollow"
                                    title="Get all the best "
                                    href="/live">Get all the
                                    best </a><a rel="nofollow"
                                                title="voyeur videos"
                                                href="/live">voyeur
                                    videos</a></div>
                            </div>
                            <div
                                className="col-lg-2 col-md-2 col-sm-12 footer-section">
                                <span
                                    className="section-title">Information</span><a
                                rel="nofollow" title="Terms &amp; Conditions"

                                href="/cms/tos">Terms &amp; Conditions</a><a
                                rel="nofollow" title="Privacy Policy"
                                href="/cms/privacy">Privacy
                                Policy</a><a rel="nofollow"
                                             title="2257 Statement"
                                             href="/cms/2257">2257
                                Statement</a><a rel="nofollow"
                                                title="DMCA / Copyright"
                                                href="/cms/dmca">DMCA /
                                Copyright</a></div>
                            <div
                                className="col-lg-2 col-md-2 col-sm-12 footer-section">
                            <span
                                className="section-title">Support &amp; Help</span><a
                                rel="nofollow" title="FAQ"
                                href="/cms/faq">FAQ</a><a
                                title="Contact Support"
                                href="/live/lantaland/living-room#/cms/contact">Contact
                                Support</a><a title="Billing" rel="nofollow"
                                              href="https://ebillinghelp.com/">Billing</a><a
                                target="_blank" title="VoyeurHouse Blog"
                                rel="noopener noreferrer"
                                href="https://blog.voyeurhouse.com">VoyeurHouse
                                Blog</a><a target="_blank"
                                           title="Join Telegram Channel"
                                           rel="noopener noreferrer"
                                           href="https://t.me/voyeurhousecom">Join
                                Telegram Channel</a>
                                <div title="Change language"
                                     className="click-nochilds m-0 mt-1 mb-1"
                                     data-sidenav="noclose"><span>English <span
                                    role="img"
                                    aria-label="united-states">🇺🇸</span></span>
                                    <span className="icon-chevron-down"></span>
                                </div>
                            </div>
                            <div
                                className="col-lg-2 col-md-2 col-sm-12 footer-section">
                            <span
                                className="section-title">Work with us</span><a
                                rel="nofollow" title="Become a Creator"
                                className="footer_becomecreator"
                                href="/model/register">Become
                                a Creator</a><a target="_blank" rel="noreferrer nofollow"
                                                title="Webmasters"
                                                href="mailto:webmaster@voyeurhouse.com">Webmasters</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom row">
                    <div className="left row"><a className="footer-social"
                                                 rel="noreferrer nofollow"
                                                 href="https://twitter.com/thevoyeurhouse"
                                                 target="_blank"><img
                        alt="X Link"
                        height="30"
                        width="30"
                        src={twitter} /></a><a
                        className="footer-social" rel="noreferrer nofollow"
                        href="https://t.me/thevoyeurhouse" target="_blank"><img
                        alt="Telegram Link" height="30" width="30"
                        src={telegram} /></a><a
                        className="footer-social" rel="noreferrer nofollow"
                        href="https://www.instagram.com/thevoyeurhouse/"
                        target="_blank"><img alt="Instagram Link" height="30"
                                             width="30"
                                             src={insta} /></a>
                    </div>
                    <div className="right row">
                        <div><img alt="RTA" className="img-fluid" height="37"
                                  width="80" src={rta} />
                        </div>
                        <div className="navbar-copy"><p
                            className="navbar-copy">2024
                            © voyeurhouse.com</p></div>
                    </div>
                </div>
                <div className="row footer-link"><a className="m-1"
                                        href="https://voyeurhouse.com/reallifecam">Reallifecam</a><a
                    className="m-1"
                    href="https://voyeurhouse.com/reallifecams">Reallifecams</a><a
                    className="m-1"
                    href="https://voyeurhouse.com/hotscope">Hotscope</a><a
                    className="m-1"
                    href="https://voyeurhouse.com/hotscope">Hotscopes</a><a
                    className="m-1"
                    href="https://voyeurhouse.com/madthumbs">Madthumbs</a><a
                    className="m-1" href="https://voyeurhouse.com/tv">Voyeur
                    House
                    TV</a><a className="m-1"
                             href="https://voyeurhouse.com/webvoyeur">Webvoyeur</a><a
                    className="m-1"
                    href="https://voyeurhouse.com/reallifecam/real-people">Real
                    People</a><a className="m-1"
                                 href="https://voyeurhouse.com/reallifecam/voyeur">Voyeur</a><a
                    className="m-1" href="https://voyeurhouse.com/voyeurlife">Voyeur
                    Life</a><a className="m-1"
                               href="https://voyeurhouse.com/spygasm">Spygasm</a><a
                    className="m-1"
                    href="https://voyeurhouse.com/voyeurhouse">Voyeurhouse</a><a
                    className="m-1"
                    href="https://voyeurhouse.com/camsoda">Camsoda</a><a
                    className="m-1" href="https://voyeurhouse.com/myfreecams">My
                    free cams</a><a className="m-1"
                                    href="https://voyeurhouse.com/myfreecams">Live
                    porn</a><a className="m-1"
                               href="https://voyeurhouse.com/downblouse">Downblouse</a><a
                    className="m-1"
                    href="https://voyeurhouse.com/upskirt">Upskirt</a><a
                    className="m-1" href="https://voyeurhouse.com/purenudism">Pure
                    nudism</a><a className="m-1"
                                 href="https://voyeurhouse.com/nude">Nude</a><a
                    className="m-1" href="https://voyeurhouse.com/realcam">Real
                    Cam</a><a className="m-1"
                              href="https://voyeurhouse.com/voyeurhit">voyeurhit</a><a
                    className="m-1"
                    href="https://voyeurhouse.com/bottomlessgirls">bottom
                    less girls</a><a className="m-1"
                                     href="https://voyeurhouse.com/chaturbate">chaturbate</a><a
                    className="m-1"
                    href="https://voyeurhouse.com/bouncingboobs">bouncing
                    boobs</a><a className="m-1"
                                href="https://voyeurhouse.com/lesbians">lesbians</a><a
                    className="m-1"
                    href="https://voyeurhouse.com/webcam">webcam</a><a
                    className="m-1" href="https://voyeurhouse.com/homecam">home
                    cam</a><a className="m-1"
                              href="https://voyeurhouse.com/hiddencam">hidden
                    cam</a><a className="m-1"
                              href="https://voyeurhouse.com/spycam">spy
                    cam</a><a className="m-1"
                              href="https://voyeurhouse.com/onlinecams">online
                    cams</a><a className="m-1"
                               href="https://voyeurhouse.com/sexycams">sexy
                    cams</a><a
                    className="m-1" href="https://voyeurhouse.com/livecams">live
                    cams</a><a className="m-1"
                               href="https://voyeurhouse.com/gay">gay</a><a
                    className="m-1" href="https://voyeurhouse.com/camvideos">cam
                    videos</a><a className="m-1"
                                 href="https://voyeurhouse.com/xxxstream">xxx
                    stream</a><a className="m-1"
                                 href="https://voyeurhouse.com/voyeurstyle">voyeur
                    style</a></div>
            </footer>
        </div>
);
}

export default App;
